import type { Nullable } from 'cadenza/utils/type-util';

/**
 * Creates an array from the given value.
 *
 * - Returns a value that is an array already.
 * - Returns an empty array if the value is nullish.
 * - Converts an array-like value to an array.
 * - Wraps a not array-like value in an array
 *
 * @param value - The value to create an array from
 * @return The existing or created array
 */
export function array<T = unknown>(value?: T | ArrayLike<T> | T[]): T[] {
  if (value == null) {
    return [];
  }
  if (Array.isArray(value)) {
    return value;
  }
  // some subclasses of Element have a `length` property, most prominently HTMLSelectElement.
  // After discussing the issue we came to the conclusion that we never want to treat Elements
  // as Array-Likes or Iterables.
  if (typeof value === 'object' && 'length' in value && !(value instanceof Element)) {
    return Array.from(value);
  }
  return [value as T]; // as required because of type narrowing after instanceof
}

/**
 * Creates an array from the given value. The resulting array doesn't contain any `null` or
 * `undefined` elements
 *
 * @param array - an array which might contain nullish values
 * @return a new array not containing any null or undefined elements
 */
export function dropNullish<T>(array: Nullable<T>[]) {
  return array.filter((v) => v != null) as T[];
}
