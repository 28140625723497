import { render } from 'lit-html';
import { spread as litSpread } from '@open-wc/lit-helpers';

import type { LitContent } from 'ui/content';

import { assert } from 'cadenza/utils/custom-error';

let container: HTMLElement & { _$litPart$?: never };

/**
 * Renders a single element using Lit and returns its DOM node.
 *
 * This allows DOM factory functions such as `createButton()` to be delegated
 * to a corresponding Lit directive so that most of the code can be shared.
 */
export function createElement<T extends Element>(content: LitContent) {
  if (!container) {
    container = document.createElement('div');
    container.classList.add('offscreen');
    document.body.append(container);
  }

  try {
    render(content, container);
    assert(container.children.length === 1, 'Exactly one child element expected');
    return container.removeChild(container.children[0]) as T;
  } finally {
    container.innerHTML = '';
    delete container._$litPart$;
  }
}

export interface LitProps {
  [key: string]: unknown;

  attrs?: {
    [attribute: string]: string;
  };
}

/**
 * Lit directive to apply a large or semi-unknown amount of attributes, boolean attributes, properties, and events to an element based on their sigil:
 * - Attributes: _no sigil_ or sub-object `attrs` (for Hyperscript compatibility)
 * - Boolean attributes: `?`
 * - Properties: `.` or camel-case (for Hyperscript compatibility)
 * - Event listeners: `@` or 'on' (for Hyperscript compatibility)
 *
 * _Note:_ While HyperScript treats everything as properties by default, Lit treats everything as attributes.
 * We cannot be fully HyperScript compatible, because for a lowercase key without the '.' sigil, we cannot know
 * whether it's meant to be a property. It will be treated as an attribute.
 */
export function spread({ attrs, ...data }: LitProps) {
  if (attrs) {
    data = { ...attrs, ...data };
  }
  return litSpread(
    Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        if (key.startsWith('on')) {
          key = '@' + key.slice(2);
        } else if (key[0] !== '.' && key !== key.toLowerCase()) {
          key = '.' + key; // Assume a property, because attributes are case-insensitive.
        }
        return [key, value];
      }),
    ),
  );
}
