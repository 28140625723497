import memoize from 'lodash-es/memoize';

interface OS {
  name: string;
  platformChecks: string[];
  userAgentChecks?: string[];
}

export const OS: { [os: string]: OS } = {
  WINDOWS: {
    name: 'Windows',
    platformChecks: ['Win16', 'Win32', 'Win64'],
    userAgentChecks: [],
  },
  MACOS: {
    name: 'MacOS',
    platformChecks: ['Macintosh', 'MacIntel', 'MacPPC', 'Darwin'],
    userAgentChecks: [],
  },
  IOS: {
    name: 'iOS',
    platformChecks: ['iPhone', 'iPod', 'iPad'],
    userAgentChecks: [],
  },
  ANDROID: {
    name: 'Android',
    platformChecks: ['Android'],
    userAgentChecks: ['Android'],
  },
  LINUX: {
    name: 'Linux',
    platformChecks: ['Linux'],
    userAgentChecks: [],
  },
  OTHER: {
    name: 'Other OS (undetermined)',
    platformChecks: ['.*'],
  },
};

// for testing
export function __determineOs__() {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;

  for (const osKey in OS) {
    const os = OS[osKey];

    for (const platformCheck of os.platformChecks) {
      if (new RegExp(platformCheck).test(platform)) {
        return os;
      }
    }

    for (const userAgentCheck of os.userAgentChecks ?? []) {
      if (new RegExp(userAgentCheck).test(userAgent)) {
        return os;
      }
    }
  }

  return OS.OTHER;
}

export const determineOs = memoize(__determineOs__);
