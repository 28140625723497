import { containsNode } from 'cadenza/utils/dom';

/**
 * A unique key for the listener
 *
 * _Note:_ The key should be treated opaque, i.e. the properties should not be used.
 */
export interface EventKey {
  target: EventTarget;
  types: string | string[];
  listener: EventListenerOrEventListenerObject;
  options: AddEventListenerOptions;
}

export type DelegateEvent<E extends Event = Event> = E & { delegateTarget?: Element };
type DelegateEventListener<E extends Event> =
  | ((event: DelegateEvent<E>) => void)
  | { handleEvent: (event: DelegateEvent<E>) => void };

/**
 * Adds an event listener to the given element.
 *
 * @param target - The element to add the listener to
 * @param types - One or more event types to listen to, either as space-separated string or as an array
 * @param listener - The event listener
 * @param [options]
 * @param [options.delegate] - A selector to filter the descendants of the element that dispatch the event
 * @return A unique key for the listener
 * @example Add a "click" listener to the list element, delegating to `.item` descendants. Later on remove the listener.
 *     const eventKey = on(list, 'click', event => {
 *       assert(this === event.delegateTarget); // an .item
 *     }, { delegate: '.item' };
 *     ...
 *     unByKey(eventKey);
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener|MDN documentation on EventTarget.addEventListener()}
 * @see {@link https://learn.jquery.com/events/event-delegation/|Understanding Event Delegation}
 */
export function on<E extends Event = Event>(
  target: EventTarget,
  types: string | string[],
  listener: DelegateEventListener<E>,
  options: AddEventListenerOptions & { delegate?: string } = {},
): EventKey {
  if (options.once) {
    // Do not pass once on the addEventListener(), because with event delegation
    // there might be multiple events before the listener is actually called.
    options = { ...options, once: false };
    const originalListener = listener;
    listener = function (this: EventTarget, event) {
      _unByKey(eventKey);
      callListener(originalListener, this, event);
    };
  }
  if (options.delegate) {
    const originalListener = listener;
    const delegate = options.delegate;
    listener = function (event) {
      const delegateTarget = (event.target as Element).closest(delegate);
      if ((target as Element).contains(delegateTarget)) {
        event.delegateTarget = delegateTarget as Element;
        callListener(originalListener, delegateTarget, event);
      }
    };
  }
  const eventListener = listener as EventListenerOrEventListenerObject;
  const eventKey = { target, types, listener: eventListener, options };
  getEventTypes(types).forEach((type) => target.addEventListener(type, eventListener, options));
  return eventKey;
}

function callListener<E extends Event>(listener: DelegateEventListener<E>, thisArg: unknown, event: DelegateEvent<E>) {
  if (typeof listener === 'function') {
    listener.call(thisArg, event);
  } else {
    listener.handleEvent(event);
  }
}

/**
 * Removes an event listener using the key returned by {@link on}.
 *
 * @param eventKey - The key returned by `on()` (or an array of keys)
 */
export function unByKey(eventKey: EventKey | undefined | (EventKey | undefined)[]) {
  if (Array.isArray(eventKey)) {
    eventKey.forEach(_unByKey);
  } else {
    _unByKey(eventKey);
  }
}

function _unByKey(eventKey: EventKey | undefined) {
  if (eventKey) {
    const { target, types, listener, options } = eventKey;
    getEventTypes(types).forEach((type) => target.removeEventListener(type, listener, options));
  }
}

function getEventTypes(types: string | string[]) {
  return Array.isArray(types) ? types : types.split(' ');
}

const USER_AGENT = typeof navigator !== 'undefined' ? navigator.userAgent.toLowerCase() : '';
const IS_MAC = USER_AGENT.indexOf('macintosh') !== -1;

export function platformModifierKey(event: KeyboardEvent | MouseEvent) {
  return IS_MAC ? event.metaKey : event.ctrlKey;
}

export function forwardEnterToClick(event: KeyboardEvent) {
  if (event.key === 'Enter' || event.key === ' ') {
    event.stopPropagation();
    (event.target as HTMLElement).click();
  }
}

export interface GenericEventListener<E extends Event> {
  (evt: E): void;
}

/**
 * Non-DOM EventTarget that allows to send/listen on events without the need to to being attached
 * to the DOM.
 */
export class Observable extends EventTarget {
  override dispatchEvent(event: Event | string) {
    if (typeof event === 'string') {
      event = new CustomEvent(event);
    }
    return super.dispatchEvent(event);
  }

  on<E extends Event>(type: string, listener: GenericEventListener<E>, options?: AddEventListenerOptions) {
    return on(this, type, listener, options);
  }

  un(type: string, listener: EventListener, options?: AddEventListenerOptions) {
    this.removeEventListener(type, listener, options);
  }
}

type ChangeEventInit = Pick<CustomEventInit, 'detail'>;

/**
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/change_event|"change" event on MDN}
 */
// unknown, because https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#customevent-is-now-a-generic-type
export class ChangeEvent<T = unknown> extends CustomEvent<T> {
  constructor(options?: ChangeEventInit);
  constructor(type: string, options?: ChangeEventInit);
  constructor(typeOrOptions?: string | ChangeEventInit, options: ChangeEventInit = {}) {
    const isTypeDefined = typeof typeOrOptions === 'string';
    super(isTypeDefined ? typeOrOptions : 'change', {
      ...(isTypeDefined ? options : typeOrOptions),
      bubbles: true,
    });
  }
}

/**
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/invalid_event|"invalid" event on MDN}
 */
export class InvalidEvent extends CustomEvent<unknown> {
  constructor() {
    super('invalid', { cancelable: true });
  }
}

export function getTabbables(container: ParentNode) {
  const tabbables = getAll(container).filter(
    (el) =>
      el.matches('button, a, input, select, summary, textarea, [tabindex], [aria-activedescendant]') &&
      (el.tabIndex !== -1 || isActive(el)) && // tabbables must be in tab order, include the active element
      !el.matches(':disabled') && // disabled elements are not tabbable
      el.offsetParent !== null,
  ); // invisible elements are not tabbable (see https://developer.mozilla.org/docs/Web/API/HTMLElement/offsetParent)
  const checkedRadioGroupNames = new Set(
    tabbables.filter((el) => isRadio(el) && el.checked).map((el) => (el as HTMLInputElement).name),
  ); // TS is sometimes just stupid ...
  // Unchecked radios in a group with a checked one are not tabbable.
  return tabbables.filter((el) => !(isRadio(el) && !el.checked && checkedRadioGroupNames.has(el.name)));
}

/** Gets all elements depth-first (like in the tab order), also recurring into shadow roots. */
function getAll(container: ParentNode): HTMLElement[] {
  return [...container.querySelectorAll<HTMLElement>('*')].flatMap((el) =>
    el.shadowRoot ? getAll(el.shadowRoot) : el,
  );
}

/**
 * Checks whether the element is active.
 */
function isActive(el: Element) {
  return el.matches(':focus');
}

function isRadio(el: HTMLElement): el is HTMLInputElement & { type: 'radio' } {
  return el instanceof HTMLInputElement && el.type === 'radio';
}

export function focusNext(tabbables: HTMLElement[] | HTMLElement) {
  if (!Array.isArray(tabbables)) {
    tabbables = getTabbables(tabbables);
  }
  const activeIndex = tabbables.indexOf(document.activeElement as HTMLElement);
  if (activeIndex < tabbables.length - 1) {
    tabbables[activeIndex + 1]?.focus();
  } else {
    focusFirst(tabbables);
  }
}

export function focusPrevious(tabbables: HTMLElement[] | HTMLElement) {
  if (!Array.isArray(tabbables)) {
    tabbables = getTabbables(tabbables);
  }
  const activeIndex = tabbables.indexOf(document.activeElement as HTMLElement);
  if (activeIndex > 0) {
    tabbables[activeIndex - 1]?.focus();
  } else {
    focusLast(tabbables);
  }
}

export function focusFirst(tabbables: HTMLElement[] | HTMLElement) {
  if (!Array.isArray(tabbables)) {
    tabbables = getTabbables(tabbables);
  }
  tabbables[0]?.focus();
}

export function focusLast(tabbables: HTMLElement[] | HTMLElement) {
  if (!Array.isArray(tabbables)) {
    tabbables = getTabbables(tabbables);
  }
  tabbables[tabbables.length - 1]?.focus();
}

/**
 * This listener for "keydown" events on a container
 * prevents the user from tabbing out of the container.
 *
 * @param event
 * @param [container]
 */
export function lockKeyboardFocus(event: KeyboardEvent, container = event.currentTarget as HTMLElement) {
  if (event.key === 'Tab') {
    const tabbables = getTabbables(container);
    const firstTabbable = tabbables[0];
    const lastTabbable = tabbables[tabbables.length - 1];
    if (!containsNode(document.activeElement, container)) {
      event.preventDefault();
      firstTabbable.focus();
    } else {
      if (event.shiftKey && isActive(firstTabbable)) {
        event.preventDefault();
        lastTabbable.focus();
      } else if (!event.shiftKey && isActive(lastTabbable)) {
        event.preventDefault();
        firstTabbable.focus();
      }
    }
  }
}

/**
 * Locks the user inside an element so that the user cannot move out of it using the "Tab" key.
 * Useful for modals where you want the user to only be able to focus tabbable elements inside.
 * Don't forget to remove the event listener when you don't need it anymore.
 *
 * @param element
 * @return eventKey - The key returned by `on()` in order to unsubscribe with `unByKey()`
 */
export function lockTabInsideElement(element: HTMLElement) {
  return on(document, 'keydown', (event: KeyboardEvent) => {
    lockKeyboardFocus(event, element);
  });
}

export function isEventInsideRect(event: MouseEvent, rect: DOMRect) {
  return (
    rect.top <= event.clientY &&
    rect.top + rect.height >= event.clientY &&
    rect.left <= event.clientX &&
    rect.left + rect.width >= event.clientX
  );
}
