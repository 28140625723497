import { mediaPrefersReducedMotion } from 'cadenza/utils/media';
import { on, unByKey } from 'cadenza/utils/event-util';

/**
 * Executes function when the animation/transition is finished or (if animations/transitions are disabled) immediately.
 * The transition has to be already started when calling this function. The complete duration of
 * the transition is taken from the computedStyle to add an additional timeout safeguarding against
 * the transition never being run.
 *
 * @param element - An element that the animation is executed on
 * @param callback - Function to execute
 */
export function executeAfterAnimation(element: HTMLElement, callback: () => unknown) {
  if (isAnimationEnabled()) {
    // When having multiple transitions listeners registered at the same time, on Chrome at least,
    // the transitionend event is not fired for the subsequent registrations sometimes. Instead
    // the transitioncancel event is fired (while the animation works just fine).
    //
    // In some cases the transition isn't executed at all. To guard against this case the timeout
    // parameter was introduced.
    const computedStyle = window.getComputedStyle(element);
    const timeout = (parseFloat(computedStyle.transitionDelay) + parseFloat(computedStyle.transitionDuration)) * 1000;
    const timeoutId = setTimeout(() => wrappedCallback(), timeout);
    const eventKey = on(element, ['transitionend', 'transitioncancel'], () => wrappedCallback());
    const wrappedCallback = () => {
      clearTimeout(timeoutId);
      unByKey(eventKey);
      callback();
    };
  } else {
    callback();
  }
}

/**
 * Checks if the animations/transitions are enabled.
 * Animations and transitions can be disabled by setting prefers-reduced-motion CSS media feature.
 * see: https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion
 *
 * @return true is animations/transitions are NOT disabled
 */
export function isAnimationEnabled() {
  return !mediaPrefersReducedMotion.matches;
}
