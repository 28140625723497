import type { Nullable } from 'cadenza/utils/type-util';
import { array } from 'cadenza/utils/array-util';

import type { nothing, TemplateResult } from 'lit-html';

/**
 * Type that is commonly used in the API of components that allow customizing their content.
 *
 * For convenience, we allow `null` and `undefined` values. Use {@link normalizeContent}
 * to normalize the content for use with the DOM.
 */
export type Content = string | Node | Nullable<string | Node>[];

export function isContent(value: unknown): value is Content {
  return isStringOrNode(value) || (Array.isArray(value) && value.every(isNullableStringOrNode));
}

function isNullableStringOrNode(value: unknown): value is string | Node {
  return value == null || isStringOrNode(value);
}

function isStringOrNode(value: unknown): value is string | Node {
  return typeof value === 'string' || value instanceof Node;
}

export function normalizeContent(content: Nullable<Content>) {
  return array<Nullable<string | Node>>(content).filter(Boolean) as (string | Node)[];
}

export type LitContent =
  | TemplateResult
  | typeof nothing
  | string
  | Node
  | (TemplateResult | typeof nothing | string | Node)[]
  | Content;
