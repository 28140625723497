import { cadenzaTopWindow } from 'cadenza/utils/iframe';

const XS_ONLY = '(min-width: 1px) and (max-width: 599px)';
const SM_UP = '(min-width: 600px)';
const MD_UP = '(min-width: 900px)';
const LG_UP = '(min-width: 1200px)';

// see .postcssrc
export const mediaXsOnly = cadenzaTopWindow.matchMedia(XS_ONLY);
export const mediaSmUp = cadenzaTopWindow.matchMedia(SM_UP);
export const mediaMdUp = cadenzaTopWindow.matchMedia(MD_UP);
export const mediaLgUp = cadenzaTopWindow.matchMedia(LG_UP);

export const mediaDialogXsOnly = cadenzaTopWindow.matchMedia(
  '(min-width: 1px) and (max-width: 599px), (min-height: 1px) and (max-height: 559px)',
);

export const mediaPrefersReducedMotion = matchMedia('(prefers-reduced-motion: reduce)');

export const currentWindowMediaXsOnly = matchMedia(XS_ONLY);
export const currentWindowMediaSmUp = matchMedia(SM_UP);
export const currentWindowMediaMdUp = matchMedia(MD_UP);

export const supportsTouch = 'ontouchstart' in window;

// We assume a physical keyboard if the device has a fine pointer (i.e. a mouse)
// as the primary input mechanism. That's not 100% correct, because there are other
// fine pointers, but it's the closest we can get to detect a physical keyboard.
export const supportsKeyboard = matchMedia('(pointer: fine').matches;
