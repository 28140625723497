import h, { type HyperScriptProps } from 'hyperscript';
import { html } from 'lit-html';
import { ref, type RefOrCallback } from 'lit-html/directives/ref.js';

import type { Content, LitContent } from 'ui/content';

import { assert } from 'cadenza/utils/custom-error';
import type { Icon } from 'cadenza/utils/icon/icon';
import { array } from 'cadenza/utils/array-util';
import type { StyleClass } from 'cadenza/utils/add-style-class';
import type { LitProps } from 'cadenza/utils/lit-util';
import { spread, createElement } from 'cadenza/utils/lit-util';

export const BUTTON_ICON_STYLE_CLASS = 'button-icon';

/**
 * Creates an anchor button.
 *
 * _Note:_ This does not and should not use the `.button` CSS class or any of its variants.
 *
 * @param label - The anchor label
 * @param options
 * @param [options.styleClass] - An additional CSS class to add
 * @return The button
 */
export function createAnchorButton(
  label: string,
  { styleClass, ...props }: HyperScriptProps & { styleClass?: StyleClass } = {},
): HTMLButtonElement {
  const button: HTMLButtonElement = h('button.d-anchor', { ...props, type: 'button' }, label);
  button.classList.add(...array(styleClass));
  return button;
}

export type ButtonVariant =
  | 'primary'
  | 'borderless'
  | 'borderless-error'
  | 'borderless-warning'
  | 'borderless-success'
  | 'link';

export type ButtonSize = 'm' | 's' | 'xs' | 'xxs';

export type ToggleButton = HTMLButtonElement & { pressed: boolean };

interface ButtonOptions<P extends boolean | undefined = undefined> {
  /** Creates a submit button if `true`. */
  submit?: boolean;
  /** A button variant */
  variant?: ButtonVariant;
  /** A button size */
  size?: ButtonSize;
  /**
   * If defined, creates a toggle button with the given pressed state.
   *
   * The pressed state is toggled automatically on click.
   */
  pressed?: P;
  /** Adds a dropdown arrow to the button. */
  dropdown?: boolean;
  /** One or more additional CSS classes to add */
  styleClass?: StyleClass;
  /** Click handler for the button */
  onclick?: (event: MouseEvent) => void;
  /** A tooltip */
  title?: string;
  /** Whether the button is disabled */
  disabled?: boolean;
  /** Whether the button is hidden */
  hidden?: boolean;
}

export type HyperScriptButtonOptions<P extends boolean | undefined = undefined> = ButtonOptions<P> & HyperScriptProps;

interface LitButtonOptions<P extends boolean | undefined = undefined> extends ButtonOptions<P>, LitProps {
  /* A Ref object or callback used to get a reference of the created DOM node */
  refOrCallback?: RefOrCallback<P extends boolean ? ToggleButton : HTMLButtonElement>;
}

function button<P extends boolean | undefined = undefined>(
  content: LitContent,
  {
    disabled,
    dropdown,
    hidden,
    onclick,
    pressed,
    refOrCallback,
    size,
    styleClass,
    submit,
    variant,
    ...rest
  }: LitButtonOptions<P> = {},
) {
  assert(!(pressed !== undefined && dropdown), 'Button cannot be both a toggle-button and a dropdown button');

  const classes = array(styleClass).concat('button');
  if (dropdown) {
    classes.push('button-with-dropdown');
  }
  if (size) {
    classes.push(`button-${size}`);
  }
  if (variant) {
    const borderlessPrefix = 'borderless-';
    if (variant.startsWith(borderlessPrefix)) {
      classes.push('button-borderless', `button-${variant.substr(borderlessPrefix.length)}`);
    } else {
      classes.push(`button-${variant}`);
    }
  }

  const buttonCallback = (button?: Element) => {
    if (!button) {
      return;
    }

    if (pressed !== undefined && !Object.hasOwn(button, 'pressed')) {
      Object.defineProperty(button, 'pressed', {
        get() {
          return button.getAttribute('aria-pressed') === 'true';
        },
        set(value) {
          button.setAttribute('aria-pressed', Boolean(value).toString());
        },
      });

      (button as ToggleButton).pressed = pressed;
    }
  };

  const originalOnclick = onclick;
  if (pressed !== undefined) {
    onclick = function (event) {
      const toggleButton = event.target as ToggleButton;
      toggleButton.pressed = !toggleButton.pressed;
      if (originalOnclick) {
        originalOnclick.call(toggleButton, event);
      }
    };
  }

  return html`
    <button
      class=${classes.join(' ')}
      type=${submit ? 'submit' : 'button'}
      @click=${onclick}
      ?disabled=${disabled}
      ?hidden=${hidden}
      ${spread(rest)}
      ${ref(refOrCallback as RefOrCallback)}
      ${ref(buttonCallback)}
    >
      ${content}
    </button>
  `;
}

/**
 * Creates a button.
 *
 * @param content - The button contents must contain some text.
 * @param [options]
 * @return The button
 */
export function createButton<P extends boolean | undefined = undefined>(
  content: Content,
  options: ButtonOptions<P> & LitProps = {},
) {
  const el = createElement<P extends boolean ? ToggleButton : HTMLButtonElement>(button<P>(content, options));
  assert(!!el.textContent?.trim(), 'Label is mandatory');
  return el;
}

/**
 * Creates an icon button.
 *
 * @param icon - The button icon (`.d-icon`)
 * @param title - The button title
 * @param [options]
 * @return The button
 */
export function createIconButton<P extends boolean | undefined = undefined>(
  icon: Icon,
  title: string,
  options: HyperScriptButtonOptions<P> = {},
) {
  assert(
    icon.classList.contains('d-icon') || icon.classList.contains('d-icon-stack'),
    'Icon must be a .d-icon or a .d-icon-stack',
  );
  const button = createButtonInternal(icon, { ...options, title });
  button.classList.add(BUTTON_ICON_STYLE_CLASS);
  // Screenreaders do not support the title attribute, so we also
  // set the aria-label if the button does not already have one.
  if (!button.hasAttribute('aria-label')) {
    button.setAttribute('aria-label', title);
  }
  return button;
}

/**
 * Creates an round button.
 *
 * @param icon - The button icon (`.d-icon`)
 * @param title - The button title
 * @param [options]
 * @return The button
 */
export function createRoundButton(
  icon: Icon,
  title: string,
  options: Pick<HyperScriptButtonOptions, 'styleClass' | 'onclick'> & { size?: 'm' | 's' } = {},
) {
  assert(icon.classList.contains('d-icon'), 'Icon must be a .d-icon');
  const button = createButtonInternal(icon, { ...options, title });
  button.classList.add('button-round');
  return button;
}

function createButtonInternal<P extends boolean | undefined = undefined>(
  content: Content,
  {
    submit = false,
    variant,
    size,
    pressed,
    dropdown = false,
    styleClass,
    onclick,
    title,
    ...props
  }: HyperScriptButtonOptions<P> = {},
) {
  assert(!(pressed !== undefined && dropdown), 'Button cannot be both a toggle-button and a dropdown button');
  assert(
    !['className', 'classList'].some((prop) => prop in props),
    'className and classList are not supported; use styleClass instead',
  );

  const button: typeof pressed extends undefined ? HTMLButtonElement : ToggleButton = h(
    'button.button',
    { ...props, type: submit ? 'submit' : 'button' },
    content,
  );

  if (title !== undefined) {
    button.title = title;
  }
  if (dropdown) {
    button.classList.add('button-with-dropdown');
  }
  if (pressed !== undefined) {
    Object.defineProperty(button, 'pressed', {
      get() {
        return button.getAttribute('aria-pressed') === 'true';
      },
      set(value) {
        button.setAttribute('aria-pressed', Boolean(value).toString());
      },
    });
    const toggleButton = button as ToggleButton;
    toggleButton.pressed = pressed;
    const originalOnclick = onclick;
    onclick = function (event) {
      toggleButton.pressed = !toggleButton.pressed;
      if (originalOnclick) {
        originalOnclick.call(button, event);
      }
    };
  }
  if (onclick) {
    button.addEventListener('click', onclick);
  }
  applyButtonStyle(button, { variant, size, styleClass });

  return button;
}

export function applyButtonStyle(
  element: HTMLButtonElement | HTMLAnchorElement,
  { variant, size, styleClass }: HyperScriptButtonOptions,
) {
  if (variant) {
    const borderlessPrefix = 'borderless-';
    if (variant.startsWith(borderlessPrefix)) {
      element.classList.add('button-borderless', `button-${variant.substr(borderlessPrefix.length)}`);
    } else {
      element.classList.add(`button-${variant}`);
    }
  }
  if (size) {
    element.classList.add(`button-${size}`);
  }
  if (styleClass) {
    element.classList.add(...array(styleClass));
  }
}

/**
 * Returns the size of an icon button.
 *
 * @param [size] - The T-shirt size of the button
 * @return The size in px of the button (depends on touch screen or not)
 */
export const iconButtonSize = (size?: ButtonSize) => {
  const button = createIconButton(h('.d-icon.visuallyhidden'), 'dummy', { size });
  document.body.append(button);
  const width = button.clientWidth;
  button.remove();
  return width;
};
